import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { AuthProvider } from './context';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)



// Pages
const Clicker = React.lazy(() => import('./views/clickers/home/TheLayout'));
const ClickerDetail = React.lazy(() => import('src/views/clickers/detail/TheLayout'));
const List = React.lazy(() => import('src/views/clickers/list/TheLayout'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Profile = React.lazy(() => import('./views/profile'));
const News = React.lazy(() => import('./views/news/home'));
const Term = React.lazy(() => import('./views/term'));
const Refund = React.lazy(() => import('./views/refund'));
const Privacy = React.lazy(() => import('./views/privacy'));
const Statement = React.lazy(() => import('./views/statement'));
const Licenses = React.lazy(() => import('./views/licenses'));
const NewDetail = React.lazy(() => import('./views/news/detail'));
const Tutorials = React.lazy(() => import('./views/tutorials'));

class App extends Component {

  render() {
    return (
      <AuthProvider>
        <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route exact path="/profile/:id" name="Profile Page" render={props => <Profile {...props}/>} />
                {/* <Route exact path="/news" name="News Page" render={props => <News {...props}/>} />
                <Route exact path="/news/:id" name="News Detail Page" render={props => <NewDetail {...props}/>} /> */}
                <Route exact path="/tutorials" name="Tutorials Page" render={props => <Tutorials {...props}/>} />
                <Route exact path="/terms-of-use" name="Terms of Use" render={props => <Term {...props}/>} />
                <Route exact path="/refund-policy" name="Refund Policy" render={props => <Refund {...props}/>} />
                <Route exact path="/privacy" name="Privacy" render={props => <Privacy {...props}/>} />
                <Route exact path="/statement" name="Statement" render={props => <Statement {...props}/>} />
                <Route exact path="/licenses" name="licenses" render={props => <Licenses {...props}/>} />
                <Route path="/clicker/:id" name="Clicker detail" render={props => <ClickerDetail {...props}/>} />
                <Route path="/clickers" name="Clicker detail" render={props => <List {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                {/* <Route exact path="/clickers/:id" name="Clicker Detail" render={props => <Clicker {...props}/>} /> */}
                <Route path="/" name="Home" render={props => <Clicker {...props}/>} />
                {/* <Route path="/" name="Home" render={props => <HomeNew {...props}/>} /> */}
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

export default App;
