import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import './style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AlertTemplate from "./alertTemplate";
import { positions, Provider , transitions} from "react-alert";
import "./i18n";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss";

import { icons } from './assets/icons'

// import { Provider } from 'react-redux'
// import store from './store'

React.icons = icons

const options = {
  position: positions.MIDDLE,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 999999,
    fontSize: window.innerWidth < 960 ? '12px' : '14px',
    fontFace: 'Nimbus Sans L',
    
  },
};

ReactDOM.render(
  <Provider template={AlertTemplate} {...options} >
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
