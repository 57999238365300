let user = localStorage.getItem('currentUser')
	? JSON.parse(localStorage.getItem('currentUser'))
	: '';
let token = localStorage.getItem('currentUser')
	? JSON.parse(localStorage.getItem('currentUser')).token
	: '';

let userName = localStorage.getItem('userName')
	? JSON.parse(localStorage.getItem('userName'))
	: '';

export const initialState = {
	user: '' || user,
	token: '' || token,
	hideLikeClicker: false,
	userName: userName,
	hideLikeNew: false,
	isShowDetail: true,
	loading: false,
	changeState: false,
	errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
			};
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				user: action.payload,
				token: action.payload.token,
				userName: action.payload.first_name,
				loading: false,
			};
		case 'LOGOUT':
			return {
				...initialState,
				user: '',
				token: '',
			};
		case 'ONLOAD':
			return {
				...initialState,
				changeState: !initialState.changeState
			};
		case 'SHOW_DETAIL':
			return {
				...initialState,
				isShowDetail: !initialState.isShowDetail
			};
		case 'SETTING':
			return {
				...initialState,
				hideLikeClicker: action.payload.attributes.hide_like_clicker,
				hideLikeNew: action.payload.attributes.hide_like_news
			};
		case 'UPDATE_USER_NAME':
			return {
				...initialState,
				userName: action.payload,
				loading: false,
			};
		case 'LOGIN_ERROR':
			return {
				...initialState,
				loading: false,
				errorMessage: action.error,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
