import axios from "axios";
import download from "downloadjs";
import { API_URL, CLIENT_ID, CLIENT_SECRET } from "./../util/constants";

const header = () => {
  const token = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).token
    : "";

  return {
    "Content-Type": "application/json",
    "Client-Id": CLIENT_ID,
    "Client-Secret": CLIENT_SECRET,
    Authorization: "Bearer " + token,
    Accept: "application/json",
  };
};

export async function socialLogin(dispatch, token, provider) {
  let loginPayload = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    access_token: token,
    provider: provider,
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await fetch(`${API_URL}/auth/social-login`, requestOptions);
    if (response.status != undefined && response.status !== 200) {
      return response.status;
    }
    let data = await response.json();
    if (data.data) {
      let user = {
        id: data.data.id,
        email: data.data.attributes.email,
        first_name: data.data.attributes.first_name,
        is_social: true,
        last_name: data.data.attributes.last_name,
        token: data.data.attributes.access_token,
      };
      dispatch({ type: "LOGIN_SUCCESS", payload: user });
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("userName", JSON.stringify(user.first_name));
      return user;
    }

    dispatch({ type: "LOGIN_ERROR", error: data.errors[0] });
    console.log(data.errors[0]);
    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error);
  }
}

export async function login(dispatch, email, password, remenber) {
  let loginPayload = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    email: email,
    password: password,
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await fetch(`${API_URL}/guest/auth/login`, requestOptions);
    if (response.status != undefined && response.status !== 200) {
      return response.status;
    }

    let data = await response.json();
    if (data.data.attributes.access_token) {
      if (remenber) {
        let remenber = {
          email: email,
          password: password,
        };

        localStorage.setItem("remenber", JSON.stringify(remenber));
      } else {
        localStorage.setItem("remenber", "");
      }
      let headers = {
        "Content-Type": "application/json",
        "Client-Id": CLIENT_ID,
        "Client-Secret": CLIENT_SECRET,
        Authorization: "Bearer " + data.data.attributes.access_token,
        Accept: "application/json",
      };

      let requestOptions = {
        method: "GET",
        headers: headers,
      };
      let response = await fetch(`${API_URL}/profile`, requestOptions);

      let profile = await response.json();

      if (profile.data) {
        let user = {
          id: profile.data.id,
          email: profile.data.attributes.email,
          first_name: profile.data.attributes.first_name,
          last_name: profile.data.attributes.last_name,
          is_social: false,
          token: data.data.attributes.access_token,
          phone: profile.data.attributes.phone,
          address: profile.data.attributes.address,
          total_amount: profile.data.attributes.total_amount,
          subscription_expiration:profile.data.attributes.subscription_expiration,
          subscription_period:profile.data.attributes.subscription_period,
          subscription_period_length:profile.data.attributes.subscription_period_length,
          subscription_recurring:profile.data.attributes.subscription_recurring,
          subscription_ref:profile.data.attributes.subscription_ref,
        };

        dispatch({ type: "LOGIN_SUCCESS", payload: user });
        localStorage.setItem("currentUser", JSON.stringify(user));
        localStorage.setItem("userName", JSON.stringify(user.first_name));
        return user;
      }
    }

    dispatch({ type: "LOGIN_ERROR", error: data.errors[0] });
    console.log(data.errors[0]);
    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    return error;
  }
}

export async function signUp(dispatch, body) {
  let loginPayload = { ...body };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await fetch(`${API_URL}/auth/register`, requestOptions);
    let data = await response.json();
    if (data.email != undefined) {
      return { email: data.email, code: 422 };
    }
    if (data.data) {
      return data;
    }
    return;
  } catch (error) {
    console.log(error);
  }
}

export async function sendContact(dispatch, body) {
  let contactPayload = { ...body };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(contactPayload),
  };

  try {
    let response = await fetch(`${API_URL}/guest/contact`, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }
    return;
  } catch (error) {
    console.log(error);
  }
}

export async function profileUpdate(dispatch, body) {
  let contactPayload = { ...body };
  const requestOptions = {
    method: "PUT",
    headers: header(),
    body: JSON.stringify(contactPayload),
  };

  try {
    let response = await fetch(`${API_URL}/users/update`, requestOptions);
    let data = await response.json();
    if (data.data) {
      // localStorage.setItem(
      //   "userName",
      //   JSON.stringify(contactPayload.first_name)
      // );
      // dispatch({
      //   type: "UPDATE_USER_NAME",
      //   payload: contactPayload.first_name,
      // });
      return data;
    }
    return;
  } catch (error) {
    console.log(error);
  }
}

export async function createOrUpdateBank(body) {
  let payload = { ...body };
  const requestOptions = {
    method: "POST",
    headers: header(),
    body: JSON.stringify(payload),
  };

  return fetch(`${API_URL}/bank-info`, requestOptions).then(handleResponse);
}

export async function checkLike(clickerId) {
  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(`${API_URL}/likes?macro_id=` + clickerId, requestOptions).then(
    handleResponse
  );
}

export async function getTerm() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(`${API_URL}/guest/terms`, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function getPrivacy() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(`${API_URL}/guest/privacies`, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function getSetting(dispatch) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(`${API_URL}/guest/settings`, requestOptions);
    let data = await response.json();
    if (data.data) {
      dispatch({ type: "SETTING", payload: data.data });
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function getTutorial() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(`${API_URL}/guest/tutorials`, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function getAds() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(
      `${API_URL}/guest/advertisements`,
      requestOptions
    );
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function getCategory(params) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };

  try {
    let url = "";
    if (params.parent_id !== undefined) {
      url = `${API_URL}/guest/categories?parent_id=${params.parent_id}`;
    } else {
      url = `${API_URL}/guest/categories`;
    }

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function getRate(params) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };

  try {
    let response = await fetch(
      `${API_URL}/guest/rates?macro_id=${params.macro_id}`,
      requestOptions
    );
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function like(clickerId) {
  const requestOptions = {
    method: "POST",
    headers: header(),
    body: JSON.stringify({ macro_id: clickerId }),
  };

  return fetch(`${API_URL}/likes`, requestOptions).then(handleResponse);
}

export async function getProfile() {
  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(`${API_URL}/profile`, requestOptions).then(handleResponse);
}

export function createMacro(data) {
  return axios.post(`${API_URL}/macros`, data, {
    headers: header(),
  });
}

export function cancelRecurring(data) {
  console.log(data)
  return axios.post(`${API_URL}/payment/cancel-recurring`, data, {
    headers: header(),
  });
}

// export function deleteAccount(data) {
//   console.log(data)
//   return axios.delete(`${API_URL}/users/delete`, data, {
//     headers: header(),
//   });
// }

export async function deleteAccount() {
  const requestOptions = {
    method: "DELETE",
    headers: header(),
  };

  return fetch(`${API_URL}/users/delete`, requestOptions).then(handleResponse);
}

export function createRate(data) {
  return axios.post(`${API_URL}/rates`, data, {
    headers: header(),
  });
}

export function updateMacro(data, id) {
  return axios.post(`${API_URL}/macros/` + id, data, {
    headers: header(),
  });
}

export async function showMacro(id) {
  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(`${API_URL}/macros/` + id, requestOptions).then(handleResponse);
}

export async function report(body) {
  return axios.post(`${API_URL}/reports`, body, {
    headers: header(),
  });
}

export async function request(body, id) {
  const requestOptions = {
    method: "POST",
    headers: header(),
    body: JSON.stringify(body),
  };

  return fetch(`${API_URL}/requirements`, requestOptions).then(handleResponse);
}

export async function reportError(body, id) {
  return axios.post(`${API_URL}/errors`, body, {
    headers: header(),
  });
}

export async function deleteMacro(id) {
  const requestOptions = {
    method: "DELETE",
    headers: header(),
  };

  return fetch(`${API_URL}/macros/` + id, requestOptions).then(handleResponse);
}

export async function listMacro(params) {
  const url = new URL(`${API_URL}/macros`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function listPackage(params) {
  const url = new URL(`${API_URL}/packages`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function getWidgetUrl(params) {
  const url = new URL(`${API_URL}/payment/get-widget-url`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function listLikes(params) {
  const url = new URL(`${API_URL}/likes`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export function createPackage(data) {
  return axios.post(`${API_URL}/mileages`, data, {
    headers: header(),
  });
}

export async function createWithDraw(body) {
  const requestOptions = {
    method: "POST",
    headers: header(),
    body: JSON.stringify(body),
  };

  return fetch(`${API_URL}/withdraws`, requestOptions).then(handleResponse);
}

export async function getWithDraw() {
  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(`${API_URL}/withdraws`, requestOptions).then(handleResponse);
}

export async function listMileages(params) {
  const url = new URL(`${API_URL}/mileages`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function totalAmount() {
  const url = new URL(`${API_URL}/mileages/total-amount`);

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function listComment(params) {
  const url = new URL(`${API_URL}/comments`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function postComment(body) {
  const requestOptions = {
    method: "POST",
    headers: header(),
    body: JSON.stringify(body),
  };

  try {
    let response = await fetch(`${API_URL}/comments`, requestOptions);
    let data = await response.json();
    if (data.message !== undefined) {
      return { code: 404, message: data.message };
    }

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function listNews(params) {
  const url = new URL(`${API_URL}/guest/news`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function checkLikeNews(id) {
  const url = new URL(`${API_URL}/news/${id}/check-like`);
  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function createLikeNews(id) {
  const url = new URL(`${API_URL}/news/${id}/likes`);
  const requestOptions = {
    method: "POST",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function showNews(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(`${API_URL}/guest/news/${id}`, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function guestListMacro(params) {
  const url = new URL(`${API_URL}/guest/macros`);
  url.search = new URLSearchParams(params).toString();
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(url, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function guestGetMacro(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };
  try {
    let response = await fetch(`${API_URL}/guest/macros/${id}`, requestOptions);
    let data = await response.json();
    if (data.data) {
      return data;
    }

    return;
  } catch (error) {
    console.log(error);
  }

  return;
}

export async function listDownLoad(params) {
  const url = new URL(`${API_URL}/downloads`);
  url.search = new URLSearchParams(params).toString();

  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function deleteDownload(id) {
  const requestOptions = {
    method: "DELETE",
    headers: header(),
  };

  return fetch(`${API_URL}/downloads/` + id, requestOptions).then(
    handleResponse
  );
}

export async function getDownloadFile(url, id) {
  return fetch(url, {
    method: "GET",
    headers: header(),
  })
    .then(function (resp) {
      if (resp.status !== 200) {
        return resp;
      }
      return resp.blob();
    })
    .then(function (blob) {
      if (blob.status === undefined) {
        return download(blob, id);
      }

      return blob;
    });
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
  window.location.replace("/home");
}

// password reset
export async function sendMailResetPassword(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  };

  try {
    let response = await fetch(
      `${API_URL}/guest/password-resets/send-mail`,
      requestOptions
    );
    let data = await response.json();
    if (data.message !== undefined) {
      return { code: 404, message: data.message };
    }

    return data;
  } catch (error) {
    console.log(error);
  }
}

// verify token
export async function sendVerifyToken(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  };

  try {
    let response = await fetch(
      `${API_URL}/guest/password-resets/verify-token`,
      requestOptions
    );
    let data = await response.json();
    if (data.message !== undefined) {
      return { code: 404, message: data.message };
    }

    return data;
  } catch (error) {
    console.log(error);
  }
}

// change password
export async function changePassword(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  };

  try {
    let response = await fetch(
      `${API_URL}/guest/password-resets/change-password`,
      requestOptions
    );
    let data = await response.json();
    if (data.message !== undefined) {
      return { code: 404, message: data.message };
    }

    return data;
  } catch (error) {
    console.log(error);
  }
}

// change password
export async function updatePasswordFromUser(body) {
  const requestOptions = {
    method: "PUT",
    headers: header(),
    body: JSON.stringify(body),
  };

  try {
    let response = await fetch(
      `${API_URL}/users/change-password`,
      requestOptions
    );
    let data = await response.json();
    if (data.message !== undefined) {
      return { code: 404, message: data.message };
    }

    return data;
  } catch (error) {
    console.log(error);
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("currentUser");
        localStorage.removeItem("token");
        window.location.replace("/home");
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
